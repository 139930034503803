<template>
  <div class="activitydetail">
    <van-nav-bar
      title="活动详情"
      left-arrow
      @click-left="onClickLeft"
    />
    <van-cell class="row1" :border="false" >
      <template #title>
        <div class="title_main">
          <div class="logo">
            <van-image
              :src="activity.logo"
              width="60px"
              height="60px"
              lazy-load
            >
              <template v-slot:loading>
                <van-loading type="spinner" size="20" />
              </template>
            </van-image>
          </div>
          <div class="title">{{activity.title}}</div>
        </div>
      </template>
      <template #label>
        <div>{{activity.description}}</div>
      </template>
    </van-cell>
    <van-cell class="row1" :border="false" center>
      <template #title>
        <van-count-down :time="activity.stoptime" format="距结束：DD天HH时mm分ss秒" />
      </template>
      <template #right-icon>
        <van-button size="small" type="primary" @click.stop="showInput">核销单号</van-button>
      </template>
    </van-cell>
    <van-cell class="row2" :border="false" center>
      <template #title>
        <div id="content" v-html="activity.content">{{ activity.content }}</div>
        <div><van-divider>没有更多啦</van-divider></div>
      </template>
    </van-cell>
    <!-- 核销单号弹框 -->
    <van-dialog
      v-model="showInputOrdersn"
      title="标题"
      show-cancel-button
      :before-close="beforeClase"
    >
      <div class="inputMain">
        <van-field
          v-model="ordersn"
          label="旗舰店订单号"
          required
          placeholder="请输入旗舰店订单号"
        />
        <van-field
          v-model="agentcode"
          label="推荐人邀请码"
          :required="noAgentcode == '1' ? true : false"
          placeholder="请输入推荐人邀请码"
        />
        <van-radio-group v-model="noAgentcode" direction="horizontal">
          <van-radio name="1" checked-color="#EE0A24" icon-size="14px">有推荐人</van-radio>
          <van-radio name="2" checked-color="#EE0A24" icon-size="14px">无推荐人</van-radio>
        </van-radio-group>
      </div>
    </van-dialog>
  </div>
</template>
<script>
export default {
  name: "activityDetail",
  data() {
    return {
      shareid: 0,
      merchid: 0,
      id: 0,
      activity: {},
      showInputOrdersn: false, // 是否显示核销单号输入框
      ordersn: '', // 用户输入的订单号
      agentcode: '', // 用户输入的邀请码
      noAgentcode: '1',
    }
  },
  mounted() {
    this.id = this.$route.query.id ? this.$route.query.id : 0;
    if (this.$route.query.shareid) {
      this.shareid = this.$route.query.shareid
    }else if (localStorage.getItem("DK_MEMBERID")) {
      this.shareid = localStorage.getItem("DK_MEMBERID");
    }
    this.merchid = this.$route.query.merchid ? this.$route.query.merchid : 0;
    this.$parent.getmerchname(this.merchid, "活动详情");
    this.getdetail();
  },
  methods: {
    onClickLeft() {
      this.$router.back();
    },
    getdetail() {
      console.log("getdetail");
      let that = null;
      that = this;
      that.$toast({
        type: 'loading',
        message: '加载中...',
        forbidClick: true,
        duration: 0
      })
      
      that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID");
      that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      that.$axios
        .post(
          that.$store.state.domain + "web/activity/getdetail",
          that.$qs.stringify({
            id: that.id
          })
        )
        .then(res => {
          console.log(res);
          that.$toast.clear();
          if (res.data.code == 100000) {
            that.activity = res.data.data;
          } else {
            that.$toast(res.data.msg ? res.data.msg : "获取活动详情失败")
          }
        })
        .catch(err => {
          that.$toast.clear();
          console.log(err);
        })
    },
    // 点击核销单号按钮
    showInput() {
      // this.ordersn = "";
      // this.agentcode = "";
      // this.showInputOrdersn = true;
      this.$router.push({
        name: "hexiaoOrder",
        query: {
          id: this.id,
          shareid: this.shareid,
          merchid: this.merchid
        }
      });
    },
    // 弹框关闭前回调
    beforeClase(action, done) {
      console.log(action);
      let that = null;
      that = this;
      if (action == 'confirm') {
        // this.ordersnconfirm();
        that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID");
        that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
        that.$axios
          .post(
            that.$store.state.domain + "web/activity/addordersn",
            that.$qs.stringify({
              id: that.id,
              ordersn: that.ordersn,
              agentcode: that.agentcode,
              noAgentcode: that.noAgentcode
            })
          )
          .then(res => {
            console.log(res);
            if (res.data.code == 100000) {
              that.$toast(res.data.msg ? res.data.msg : '保存成功');
              done();
            }else {
              that.$toast(res.data.msg ? res.data.msg : '保存失败');
              done(false);
            }
          })
      }else {
        done();
      }
    },
  }
}
</script>
<style lang="less">
  .activitydetail {
    width: 100%;
    min-height: 100%;
    overflow: hidden;
    .van-nav-bar .van-icon {
      color: #333;
    }
    .row1 {
      overflow: hidden;
      padding-bottom: 0;
      .van-cell__title {
        flex: 1;
        overflow: hidden;
        text-align: left;
        .title_main {
          display: flex;
          overflow: hidden;
          .logo {
            width: 60px;
            height: 60px;
            border-radius: 4px;
            overflow: hidden;
            margin-right: 10px;
            .van-image {
              display: block;
            }
          }
          .title {
            flex: 1;
            overflow: hidden;
            font-size: 16px;
          }
        }
        .van-cell__label {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
    .row2 {
      overflow: hidden;
      padding-bottom: 50px;
      .van-cell__title {
        flex: 1;
        overflow: hidden;
        text-align: left;
        p {
          margin: 0;
          img:not(.kindeditor-ku-img) {
            width: 100%;
            display: block;
          }
        }
        img:not(.kindeditor-ku-img) {
          width: 100%;
          display: block;
        }
      }
    }
    .inputMain {
      padding: 10px;
      overflow: hidden;
      .van-cell__value {
        flex: 1;
      }
      .van-radio-group {
        width: 100%;
        margin-top: 10px;
        .van-radio {
          flex: 1;
          justify-content: center;
        }
        .van-radio--horizontal {
          margin-right: 0;
        }
        .van-radio__label {
          font-size: 14px;
        }
      }
    }
  }
</style>